// @ts-nocheck
import { ApplyPluginsType, dynamic } from '/usr/src/app/node_modules/.pnpm/@umijs+runtime@3.2.17_react@16.14.0/node_modules/@umijs/runtime';
import { plugin } from './plugin';
import LoadingComponent from '@/components/loading';

export function getRoutes() {
  const routes = [
  {
    "path": "/privacyPolicy",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__docs__privacyPolicy' */'@/pages/docs/privacyPolicy'), loading: LoadingComponent}),
    "title": "PRIVACY POLICY",
    "exact": true
  },
  {
    "path": "/termsOfService",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__docs__termsOfService' */'@/pages/docs/termsOfService'), loading: LoadingComponent}),
    "title": "INDEPENDENT CONTRACTOR TERMS OF SERVICE",
    "exact": true
  },
  {
    "path": "/privacyPolicyUs",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__docs__privacyPolicyUs' */'@/pages/docs/privacyPolicyUs'), loading: LoadingComponent}),
    "title": "PRIVACY POLICY",
    "exact": true
  },
  {
    "path": "/termsOfServiceUs",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__docs__termsOfServiceUs' */'@/pages/docs/termsOfServiceUs'), loading: LoadingComponent}),
    "title": "INDEPENDENT CONTRACTOR TERMS OF SERVICE",
    "exact": true
  },
  {
    "path": "/privacyPolicyNz",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__docs__privacyPolicyNz' */'@/pages/docs/privacyPolicyNz'), loading: LoadingComponent}),
    "title": "PRIVACY POLICY",
    "exact": true
  },
  {
    "path": "/termsOfServiceNz",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__docs__termsOfServiceNz' */'@/pages/docs/termsOfServiceNz'), loading: LoadingComponent}),
    "title": "INDEPENDENT CONTRACTOR TERMS OF SERVICE",
    "exact": true
  },
  {
    "path": "/privacyPolicyCa",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__docs__privacyPolicyCa' */'@/pages/docs/privacyPolicyCa'), loading: LoadingComponent}),
    "title": "PRIVACY POLICY",
    "exact": true
  },
  {
    "path": "/termsOfServiceCa",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__docs__termsOfServiceCa' */'@/pages/docs/termsOfServiceCa'), loading: LoadingComponent}),
    "title": "INDEPENDENT CONTRACTOR TERMS OF SERVICE",
    "exact": true
  },
  {
    "path": "/t",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__tracking' */'@/pages/tracking'), loading: LoadingComponent}),
    "title": "订单查询",
    "exact": true
  },
  {
    "path": "/search",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__search' */'@/pages/search'), loading: LoadingComponent}),
    "title": "物流查询",
    "exact": true
  },
  {
    "path": "/invoice",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__invoice' */'@/pages/invoice'), loading: LoadingComponent}),
    "title": "Invoice Preview",
    "exact": true
  },
  {
    "path": "/faq",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__faq' */'@/pages/faq'), loading: LoadingComponent}),
    "title": "联系我们",
    "exact": true
  },
  {
    "path": "/authorize",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__authorize' */'@/pages/authorize'), loading: LoadingComponent}),
    "title": "飞书授权登录",
    "exact": true
  },
  {
    "path": "/f/login",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__fleet__login' */'@/pages/fleet/login'), loading: LoadingComponent}),
    "title": "Login",
    "exact": true
  },
  {
    "path": "/f/driver-data",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__fleet__driverData' */'@/pages/fleet/driverData'), loading: LoadingComponent}),
    "title": "Driver Data",
    "exact": true
  },
  {
    "path": "/f/details",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__fleet__details' */'@/pages/fleet/details'), loading: LoadingComponent}),
    "title": "Details",
    "exact": true
  },
  {
    "path": "/f/unsettlementData",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__fleet__unsettlementData' */'@/pages/fleet/unsettlementData'), loading: LoadingComponent}),
    "title": "未结算数据",
    "exact": true
  },
  {
    "path": "/f/forgotPassword",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__fleet__forgotPassword' */'@/pages/fleet/forgotPassword'), loading: LoadingComponent}),
    "title": "忘记密码",
    "exact": true
  },
  {
    "path": "/f/historicalBill",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__fleet__historicalBill' */'@/pages/fleet/historicalBill'), loading: LoadingComponent}),
    "title": "历史账单",
    "exact": true
  },
  {
    "path": "/service",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__service' */'@/pages/service'), loading: LoadingComponent}),
    "title": "在线客服",
    "exact": true
  },
  {
    "path": "/",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__pc__home' */'@/pages/pc/home'), loading: LoadingComponent}),
    "title": "FASTHORSE EXPRESS",
    "exact": true
  },
  {
    "path": "/pc/search",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__pc__search' */'@/pages/pc/search'), loading: LoadingComponent}),
    "title": "物流查询",
    "exact": true
  },
  {
    "path": "/pc/faq",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__pc__faq' */'@/pages/pc/faq'), loading: LoadingComponent}),
    "title": "联系我们",
    "exact": true
  },
  {
    "path": "/pc/service",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__pc__service' */'@/pages/pc/service'), loading: LoadingComponent}),
    "title": "在线客服",
    "exact": true
  },
  {
    "path": "/*",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'@/pages/404'), loading: LoadingComponent}),
    "title": "NOT FOUND",
    "exact": true
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
