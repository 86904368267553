import docCookies from '@/utils/cookie';

/**
 * **非生产环境** 添加 vconsole 控制
 * @description 可通过2种途径开启 vconsole
 * 1. 链接上增加一个 query : `isDebug=true`
 * 2. cookie 里设置 `vconsole_visible` 为 true
 */
function debugHook() {
  let isShowVConsole = false;
  const DEBUG_HOOK_VALUE = 'vconsole_visible';

  if (window.location.search.includes('isDebug=true')) {
    // 填到cookie里方便下次直接取值
    docCookies.setItem(
      DEBUG_HOOK_VALUE,
      'true',
      2 * 24 * 60 * 60,
      '/',
      window.location.host.split('.').slice(-2).join('.').replace(/:\d*$/g, ''),
    );
    localStorage.setItem(DEBUG_HOOK_VALUE, 'true');
    isShowVConsole = true;
  } else if (docCookies.getItem(DEBUG_HOOK_VALUE) === 'true') {
    isShowVConsole = true;
  } else if (localStorage.getItem(DEBUG_HOOK_VALUE) === 'true') {
    isShowVConsole = true;
  }

  if (process.env.NODE_ENV !== 'production' && isShowVConsole) {
    import('vconsole').then((module) => {
      const { default: VConsole } = module;
      // eslint-disable-next-line no-new
      new VConsole();
    });
  }
}

export default debugHook;
