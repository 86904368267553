export default {
  '订单查询': '訂單查詢',
  '帮助？': '幫助？',
  '寄件地': '寄件地',
  '预计送达': '預計送達',
  '快递单号': '快遞單號',
  '您的包裹': '您的包裹',
  '完整路径': '完整路徑',
  '更多': '更多',
  '查询其他包裹': '查詢其他包裹',
  '已送达': '已送達',
  '${realReceiverName} 已签收': '已簽收',
  '配送评价': '配送評價',
  '允许我们与您取得联系？': '允許我們與您取得聯繫？',
  '同意': '同意',
  '不同意': '不同意',
  '备注': '備註',
  '添加备注': '添加備註',
  '修改备注': '修改備註',
  '如果包裹当前正在配送中，司机可能看不到，暂不支持通过备注修改地址': '如果包裹當前正在配送中，司機可能看不到，暫不支持通過備註修改地址',
  '如果您有需要提醒司机的事宜,可以通过备注告诉他': '如果您有需要提醒司機的事宜,可以通過備註告訴他',
  '确定': '確定',
  '取消': '取消',
  '系统显示我的包裹“已丢失”，我应该怎么办？': '系統顯示我的包裹“已丟失”，我應該怎麼辦？',
  '很抱歉，我们会尽快联系卖家进行赔偿。请联络卖家处理。': '很抱歉，我們會盡快聯繫賣家進行賠償。請聯絡賣家處理。',
  '系统显示我的包裹“已签收”，但是我没找到，我应该怎么办？': '系統顯示我的包裹“已簽收”，但是我沒找到，我應該怎麼辦？',
  '请参考签收图片，并询问邻居或大厦管理员。如果仍未找到，建议联络警方处理。': '請參考簽收圖片，並詢問鄰居或大廈管理員。如果仍未找到，建議聯絡警方處理。',
  '系统显示我的包裹“已签收”，但是签收图片不是我家，我应该怎么办？': '系統顯示我的包裹“已簽收”，但是簽收圖片不是我家，我應該怎麼辦？',
  '很抱歉，请将Tracking no、系统签收时间、正确地址信息（附正确地址照片）、包裹价值（附订单截图）电邮至我们的邮箱customer_service@fasthorse.com.au，以便我们进一步跟进。': '很抱歉，請將Tracking no、系統簽收時間、正確地址信息（附正確地址照片）、包裹價值（附訂單截圖）電郵至我們的郵箱customer_service@fasthorse.com.au，以便我們進一步跟進。',
  '我买的东西少件了/这不是我买的东西，我应该怎么办？': '我買的東西少件了/這不是我買的東西，我應該怎麼辦？',
  '很抱歉，FHE专注尾程配送，我们只负责送达包裹。包裹内容物有问题请及时联系商家处理。': '很抱歉，FHE專注尾程配送，我們只負責送達包裹。包裹內容物有問題請及時聯繫商家處理。',
  '我的包裹破损了，内容物已经损坏，我应该怎么办？': '我的包裹破損了，內容物已經損壞，我應該怎麼辦？',
  '请参考签收图片。若签收时外包装破损，请将Tracking no、系统签收时间、包裹照片、包裹价值（附订单截图）、其他有用资料（如有）电邮至我们的邮箱customer_service@fasthorse.com.au，以便我们进一步跟进。': '請參考簽收圖片。若簽收時外包裝破損，請將Tracking no、系統簽收時間、包裹照片、包裹價值（附訂單截圖）、其他有用資料（如有）電郵至我們的郵箱customer_service@fasthorse.com.au，以便我們進一步跟進。',
  '系统显示我的包裹“已为您创建订单”，我何时才能收到？': '系統顯示我的包裹“已爲您創建訂單”，我何時才能收到？',
  '请耐心等待，包裹尚未到达仓库，入库后我们将尽快安排派送。': '請耐心等待，包裹尚未到達倉庫，入庫後我們將盡快安排派送。',
  '系统显示我的包裹“待配送”，我何时才能收到？': '系統顯示我的包裹“待配送”，我何時才能收到？',
  '请耐心等待，我们将尽快安排派送。': '請耐心等待，我們將盡快安排派送。',
  '系统显示我的包裹“派送中”，我何时才能收到？': '系統顯示我的包裹“派送中”，我何時才能收到？',
  '请耐心等待，通常情况下司机将于24小时内送达，实际送达时间视情况而定。': '請耐心等待，通常情況下司機將於24小時內送達，實際送達時間視情況而定。',
  '系统显示我的包裹“派送失败返仓中”，我何时才能收到？': '系統顯示我的包裹“派送失敗返倉中”，我何時才能收到？',
  '请耐心等待，我们会尽快安排第二次派送，或将包裹退回至卖家。请关注物流信息。': '請耐心等待，我們會盡快安排第二次派送，或將包裹退回至賣家。請關注物流信息。',
  '系统显示我的包裹“已退运”，我何时才能收到？': '系統顯示我的包裹“已退運”，我何時才能收到？',
  '很抱歉，我们已将包裹退回卖家，请联络卖家处理。': '很抱歉，我們已將包裹退回賣家，請聯絡賣家處理。',
  '系统显示我的包裹“已转运”，我何时才能收到？': '系統顯示我的包裹“已轉運”，我何時才能收到？',
  '请耐心等待，我们已将包裹转至其他公司配送。您仍可在我们的网站追踪物流。': '請耐心等待，我們已將包裹轉至其他公司配送。您仍可在我們的網站追蹤物流。',
  '为什么会显示“导航/地址错误”？': '爲什麼會顯示“導航/地址錯誤”？',
  '很抱歉，可能是由于系统导航无法正确定位地址，我们将尽快安排转至其他公司配送或退回卖家。': '很抱歉，可能是由於系統導航無法正確定位地址，我們將盡快安排轉至其他公司配送或退回賣家。',
  '我是否能到仓库自提？': '我是否能到倉庫自提？',
  '抱歉，现时不支持仓库自取。请耐心等待，我们将尽快将包裹送达。': '抱歉，現時不支持倉庫自取。請耐心等待，我們將盡快將包裹送達。',
  '我是否可以选择送达时间？': '我是否可以選擇送達時間？',
  '抱歉，现时不支持选择送达时间。请耐心等待，我们将尽快将包裹送达。': '抱歉，現時不支持選擇送達時間。請耐心等待，我們將盡快將包裹送達。',
  '我是否可以修改收货地址？': '我是否可以修改收貨地址？',
  '抱歉，现时不支持修改送货地址。如有相关需求请联系卖家处理。': '抱歉，現時不支持修改送貨地址。如有相關需求請聯繫賣家處理。',
  '我是否可以要求司机送至指定位置？': '我是否可以要求司機送至指定位置？',
  '抱歉，现时不支持要求送至指定位置，我们的司机将会放置在安全地点。': '抱歉，現時不支持要求送至指定位置，我們的司機將會放置在安全地點。',
  '我收到了一个不属于自己的包裹，我应该怎么办？': '我收到了一個不屬於自己的包裹，我應該怎麼辦？',
  '感谢您的告知，很抱歉给您带来不便。您的反馈对我们至关重要，请将Tracking no、您的联系方式、错送地址信息电邮至我们的邮箱customer_service@fasthorse.com.au，以便我们进一步跟进。': '感謝您的告知，很抱歉給您帶來不便。您的反饋對我們至關重要，請將Tracking no、您的聯繫方式、錯送地址信息電郵至我們的郵箱customer_service@fasthorse.com.au，以便我們進一步跟進。',
  '我想知道寄件方/我想查询订单详情？': '我想知道寄件方/我想查詢訂單詳情？',
  '很抱歉，FHE专注尾程配送，我们只负责送达包裹。关于包裹详情请及时联系商家处理。': '很抱歉，FHE專注尾程配送，我們只負責送達包裹。關於包裹詳情請及時聯繫商家處理。',
  '常见问题': '常見問題',
  '其他问题': '其他問題',
  '此答案是否解决您的问题': '此答案是否解決您的問題',
  '已解决': '已解決',
  '未解决': '未解決',
  '${orderNo}配送信息如下': '${orderNo}配送信息如下',
  '未查询到${orderNo}订单信息': '未查詢到${orderNo}訂單信息',
  '物流查询': '物流查詢',
  '查询': '查詢',
  '请输入运单号查询': '請輸入運單號查詢',
  '查看签收情况': '查看簽收情況',
  '请输入寄件人或收件人手机号后4位完成验证': '請輸入寄件人或收件人手機號後4位完成驗證',
  '输入不正确，请重新输入': '輸入不正確，請重新輸入',
  '包裹放在': '包裹放在',
  '配送情况': '配送情況',
  '包裹交给': '包裹交給',
  '签收人': '簽收人',
  '收件人或同住人': '收件人或同住人簽收',
  '前门': '前門',
  '后门': '後門',
  '前台或门卫': '前臺或門衛',
  '信箱': '信箱',
  '快递柜': '快遞櫃',
  '其他位置': '其他位置',
  '取件码': '取件碼',
  '联系我们': '聯繫我們',
  '请您输入收件人的姓查看签收信息': '請您輸入收件人的姓查看簽收信息',
  '请输入': '請輸入',
  '首页': '首頁',
  '向着你的': '向著你的',
  '理想生活前进': '理想生活前進',
  '今天就开始驾驶和赚钱': '今天就開始駕駛和賺錢',
  '下载 FastHorse Express APP': '下載 FastHorse Express APP',
  '使用自己的汽车，在空闲时间投送小件包裹': '使用自己的汽車，在空閒時間投送小件包裹',
  '每小时收入可达$30澳元': '每小時收入可達$30',
  '上手简单': '上手簡單',
  '简洁而完善的在线培训系统，15分钟掌握所有工作内容；': '簡潔而完善的在線培訓系統，15分鐘掌握所有工作內容；',
  '工作轻松': '工作輕鬆',
  '免费智能路线规划系统，辅助您用最短路程完成包裹投递；': '免費智能路線規劃系統，輔助您用最短路程完成包裹投遞；',
  '时间灵活': '時間靈活',
  'Individual contractor合作模式，工作时间自主安排；': 'Individual contractor合作模式，工作時間自主安排；',
  '收入保障': '收入保障',
  '收入记录清晰透明，每周自动结算打款。': '收入記錄清晰透明，每周自動結算打款。',
  '加入这份事业，您需要具备：': '加入這份事業，您需要具備：',
  '1. 年满20周岁及以上': '1. 年滿20周歲及以上',
  '2. 居住在墨尔本（这是FHE已开通服务的城市）': '2. 居住在FHE已開通服務的城市',
  '3. 拥有一辆符合条件的车': '3. 擁有一輛符合條件的車',
  '汽车、SUV、小型货车或货车任一种都可以。': '汽車、SUV、小型貨車或貨車任一種都可以。',
  '不同车型关系到一次可装包裹的数量，根据我们的经验，一般四门car一次可装载4h的包裹，SUV大约6.5h，无后座的van可装8h的包裹。': '不同車型關係到一次可裝包裹的數量，根據我們的經驗，一般四門car一次可裝載4h的包裹，SUV大約6.5h，無後座的van可裝8h的包裹。',
  '4. 有完整且无限制的、剩余有效期超过3个月的澳大利亚驾照': '4. 有完整且無限制的、剩餘有效期超過3個月的当地駕照',
  '5. 完善的车辆保险': '5. 完善的車輛保險',
  '所需的车险除了对第三方人身伤害的强制险外，您还须包括第三方财产损害的保险': '所需的車險除了對第三方人身傷害的強制險外，您還須包括第三方財產損害的保險',
  '6. 拥有澳大利亚企业编号 (ABN)。': '6. 擁有可工作證明（SSN、ABN、NZBN等訊息）。',
  '7. 通过背景调查确认。': '7. 通過背景調查確認。',
  '今天就': '今天就',
  '开始驾驶和赚钱': '開始駕駛和賺錢',
  '手机扫描二维码下载 FastHorse Express App': '手機掃描二維碼下載 FastHorse Express App',
  '选择语言': '選擇語言',
  '国家或地区': '國家或地區',
  '澳大利亚': '澳大利亞',
  '美国': '美國',
  '新西兰': '新西蘭',
  '加拿大': '加拿大',
  '欢迎使用PDA系统': '歡迎使用PDA系統',
  '使用飞书扫描二维码登录': '使用飛書掃描二維碼登錄',
  '请输入账号': '請輸入賬號',
  '请输入密码': '請輸入密碼',
  '账号不能为空': '賬號不能為空',
  '密码不能为空': '密碼不能為空',
  '使用账号密码登录': '使用賬號密碼登錄',
  '登录': '登錄',
  '请输入运单号': '請輸入運單號',
  '在线客服': '在線客服',
  '待揽收': '待攬收',
  '包裹异常': '包裹異常',
  '您可能遇到的问题': '您可能遇到的問題',
  '什么时候能送达': '什么时候能送达',
  '修改收件地址': '修改收件地址',
  '添加配送备注': '添加配送備註',
  '已添加备注': '已添加備註',
  '添加备注后，我们会提醒司机查看。但如果司机正在配送中可能无法及时查看到备注信息。': '添加備註後，我們會提醒司機查看。但如果司機正在配送中可能無法及時查看到備註信息。',
  '${finishTime}在${receivedType}签收': '${finishTime}在${receivedType}簽收',
  '我们暂未收到包裹，一般会在收到包裹之后3天完成配送': '我們暫未收到包裹，一般會在收到包裹之後3天完成配送',
  '请输入您的问题': '請輸入您的問題',
  '验证身份信息失败，请重新输入': '驗證身份信息失敗，請重新輸入',
  '请输入收件人的姓氏': '請輸入收件人的姓氏',
  '您的包裹异常，我们正在处理': '您的包裹異常，我們正在處理',
  '请输入正确的邮箱': '請輸入正確的郵箱',
  '提交失败，请重试': '提交失敗，請重試',
  '提交成功': '提交成功',
  '请输入您的电子邮件地址': '請輸入您的電子郵件地址',
  '我们将与您沟通结果': '我們將與您溝通結果',
  '已添加的备注': '已添加的備註',
  '添加失败，请重试': '添加失敗，請重試',
  '会话已超时，可返回上一页重新进入': '會話已超時，可返回上一頁重新進入',
  '预计今天送达': '預計今天送達',
  '未收到包裹': '未收到包裹',
  '请输入备注': '請輸入備註',
  '为进一步协助我们调查此问题，以及确保解决问题，我们善意地请求您向我们提供以下信息': '爲進一步協助我們調查此問題，以及確保解決問題，我們善意地請求您向我們提供以下信息',
  '房屋或邮箱照片': '房屋或郵箱照片',
  '请上传房屋或邮箱照片': '請上傳房屋或郵箱照片',
  '图片上传大小不要超过10MB,请重新上传！': '圖片上傳大小不要超過10MB,請重新上傳！',
  '签收图片不是我家': '簽收圖片不是我家',
  '签收图片是我家但是找不到包裹': '簽收圖片是我家但是找不到包裹',
  '其他': '其他',
  '邮箱': '郵箱',
  '欢迎加入FastHorse': '歡迎加入FastHorse',
  '手机号/电子邮箱': '手机号/電子郵箱',
  '手机号码或电子邮箱': '手機號碼或電子郵箱',
  '请输入你的账号': '請輸入賬號',
  '请输入登录密码': '請輸入登錄密碼',
  '请输入你的密码': '請輸入你的密碼',
  '找回密码': '找回密碼',
  '密码必须是8-16位英文字母、数字、符号组合': '密碼必須是8-16位英文字母、數字、符號組合',
  '密码设置成功': '密碼設置成功',
  '忘记密码': '忘記密碼',
  '请输入您的手机号码': '請輸入您的手機號碼',
  '手机号码': '手機號碼',
  '短信验证码已通过短信已发送至': '短信驗證碼已通過短信已發送至',
  '如果你没有收到验证码，请检查垃圾信息': '如果你沒有收到驗證碼，請檢查垃圾信息',
  '重新发送验证码': '重新發送驗證碼',
  '获取验证码': '獲取驗證碼',
  '设置登录密码': '設置登錄密碼',
  '请输入新密码': '請輸入新密碼',
  '下一步': '下一步',
  '完成': '完成',
  '未结算数据': '未結算數據',
  '历史账单': '歷史賬單',
  '司机数据': '司機數據',
  '退出登录': '退出登錄',
  '刷新': '刷新',
  '历史数据': '歷史數據',
  '合计金额': '合計金額',
  '佣金': '佣金',
  '配送费': '配送費',
  '补贴': '補貼',
  '扣款': '扣款',
  '配送数据': '配送數據',
  '个包裹': '个包裹',
  '箱金额': '箱金額',
  '待接单': '待接單',
  '待取货': '待取貨',
  '配送中': '配送中',
  '待返仓': '待返倉',
  '等待接单': '等待接單',
  '派单时间': '派單時間',
  '等待取货': '等待取貨',
  '计划取货时间': '計劃取貨時間',
  '取货已迟到': '取貨已遲到',
  '个包裹剩余': '個包裹剩餘',
  '计划完成时间': '計劃完成時間',
  '配送已完成': '配送已完成',
  '完成配送时间': '完成配送時間',
  '${num}个异常包裹未返仓': '${num}個異常包裹未返倉',
  '暂无数据': '暫無數據',
  '${num}个包裹': '${num}個包裹',
  '详情': '詳情',
  '最少保留一个': '最少保留一個',
  '完成箱数': '完成箱數',
  '完成包裹数': '完成包裹數',
  '数据趋势': '數據趨勢',
  '数据明细': '數據明細',
  '(${num}条)': '(${num}條)',
  '序号': '序號',
  '日期': '日期',
  '司机姓名': '司機姓名',
  '电子邮箱': '電子郵箱',
  '结算状态': '結算狀態',
  '未结算': '未結算',
  '已结算': '已结算',
  '查看明细': '查看明細',
  '税': '稅',
  '注册日期': '註冊日期',
  '所有司机': '所有司機',
  '开始日期': '開始日期',
  '结束日期': '結束日期',
  '请选择结束日期': '請選擇結束日期',
  '司机统计': '司機統計',
  '注册司机': '註冊司機',
  '审核通过司机': '審覈通過司機',
  '最近7天活跃司机': '最近7天活躍司機',
  '封禁司机': '封禁司機',
  '司机明细': '司機明細',
  '(${num}个)': '(${num}個)',
  '账号状态': '賬號狀態',
  '可配送周期': '可配送週期',
  '每日工作时长': '每日工作時長',
  '到底了~': '到底了~',
  '小时': '小時',
  '您的交货情况如何?': '您的交貨情況如何?',
  '匿名': '匿名',
  '不满意': '不滿意',
  '还行': '還行',
  '满意': '滿意',
  '已完成评价': '已完成評價',
  '已超出可评价期限': '已超出可評價期限',
  '非常抱歉，我们很重视你的反馈， 你的反馈将对司机的收入造成影响，我们将会继续提升服务质量': '非常抱歉，我們很重視你的回饋， 你的回饋將對司機的收入造成影響，我們將會繼續提升服務品質',
  '感谢你的反馈，我们将继续提升服务质量': '感謝你的回饋，我們將繼續提升服務品質',
  '知道了': '知道了',
  '需在签收后 7 天内进行评价': '需在簽收後 7 天內進行評價',
  '匿名评价': '匿名評價',
  '请详细描述你遇到的问题': '請詳細描述你遇到的問題',
  '期望得到你的建议': '期望得到你的建議',
  '不满意，我要吐槽': '不滿意，我要吐槽',
  '还行，这些方面还能做的更好': '還行，這些方面還能做的更好',
  '服务太棒了，我想夸夸配送员': '服務太棒了，我想誇誇配送員',
  '请选择或填写不满意的原因': '請選擇或填寫不滿意的原因',
};
