import moment from 'moment';

// timeZone 列表
export const timeZones = [
  'UTC +14',
  'UTC +13:45',
  'UTC +13',
  'UTC +12:45',
  'UTC +12',
  'UTC +11',
  'UTC +10:30',
  'UTC +10',
  'UTC +9:30',
  'UTC +9',
  'UTC +8:45',
  'UTC +8:30',
  'UTC +8',
  'UTC +7',
  'UTC +6:30',
  'UTC +6',
  'UTC +5:45',
  'UTC +5:30',
  'UTC +5',
  'UTC +4:30',
  'UTC +4',
  'UTC +3:30',
  'UTC +3',
  'UTC +2',
  'UTC +1',
  'UTC +0',
  'UTC -1',
  'UTC -2',
  'UTC -2:30',
  'UTC -3',
  'UTC -3:30',
  'UTC -4',
  'UTC -5',
  'UTC -6',
  'UTC -7',
  'UTC -8',
  'UTC -9',
  'UTC -9:30',
  'UTC -10',
  'UTC -11',
] as const;

// formats 下标对照枚举
export enum FORMAT_DATE {
  START, // 起始时间格式下标
  END, // 结束时间格式下标
  STANDARD, // 标准时间格式下标
  TIME, // 时间格式下标
}

// 三种日期格式
export const formats = [
  'YYYY-MM-DD 00:00:00',
  'YYYY-MM-DD 23:59:59',
  'YYYY-MM-DD HH:mm:ss',
  'HH:mm:ss',
];

export default class TimeZone {
  // 当前选择的时区，优先从初始化获取设置，初始化未配置取本地缓存，无缓存则默认当前时区
  static timeZone: string;

  // 本地缓存localstorage的key值
  static cacheKey = 'timeZone';

  // 当前时区
  static localTimeZone: string;

  // 时区偏移（以毫秒为单位）
  static offsetInMilliseconds: number;

  constructor(timeZone?: string) {
    TimeZone.init(timeZone);
  }

  /**
   * 初始化timeZone和时区偏移量
   * @param timeZone
   */
  static init(timeZone?: string): void {
    this.localTimeZone = this.getFormatLocalTimeZone();
    this.timeZone = timeZone || localStorage.getItem(this.cacheKey) || this.localTimeZone;
    this.getOffsetInMilliseconds(this.timeZone);
  }

  /**
   * 切换timeZone，更新时区偏移量，缓存timeZone到本地，重刷页面
   * @param timeZone
   */
  static setTimeZone(timeZone: string): void {
    this.timeZone = timeZone;
    this.getOffsetInMilliseconds(this.timeZone);
    localStorage.setItem(this.cacheKey, timeZone);
    window.location.reload();
  }

  /**
   * 获取当前时区的UTC标准时区格式
   * @returns
   */
  static getFormatLocalTimeZone = (): string => {
    let offset = new Date().getTimezoneOffset();
    const positive = offset <= 0 ? '+' : '-';
    offset = Math.abs(offset);
    const hour = Math.floor(offset / 60);
    const minute = `${offset % 60}`.padStart(2, '0');
    return `UTC ${positive}${hour}${+minute ? `:${minute}` : ''}`;
  };

  /**
   * 获取传入时区偏移（以毫秒为单位）
   * @param timeZone
   * @returns
   */
  static getOffsetInMilliseconds(timeZone: string): number {
    // 提取时区符号和时间
    const [symbol, time] = timeZone?.replace(/UTC (\+|-)(.*)/g, '$1,$2').split(',');
    // 分割时间
    const parts = time.split(':');
    // 将小时转成分钟
    const minutes = +`${symbol}${+parts[0] * 60 + (+parts[1] || 0)}`;
    // 获取传入时区偏移（以毫秒为单位）
    this.offsetInMilliseconds = minutes * 60 * 1000;
    return this.offsetInMilliseconds;
  }

  /**
   * 根据当前选择时区转化成 UTC +0 时间
   * @param date
   * @returns
   */
  static convertToGreenwichTime(date: Date | string): string {
    // AntD ProTable 会将空值转成 element 对象，此处对其做隐式转换判断
    if (date && date != null && date != '') {
      // 将传入日期转化成 Date 标准格式
      const now = new Date(date);
      // 将时间转成（UTC +0）时间
      const greenwichTime = new Date(now.getTime() - this.offsetInMilliseconds);
      // 格式化时间
      return moment(greenwichTime).format(formats[FORMAT_DATE.STANDARD]);
    }
    return '-';
  }

  /**
   * 将 UTC +0 时间转化成当前选择时区时间，可用于自定义格式渲染
   * 使用示例 ProColumns:
   * {
   *   ...
   *      render: TimeZone.convertToCurrentTime
   *   或
   *      render: (_, { time1, time2 }: { time1: string, time2: string }) =>
   *      `${TimeZone.convertToCurrentTime(time1)}-${TimeZone.convertToCurrentTime(time2)}`
   *   ...
   * }
   * @param date
   * @returns
   */
  static convertToCurrentTime(date: Date | string): string {
    // AntD ProTable 会将空值转成 element 对象，此处对其做隐式转换判断
    if (date && date != null && date != '') {
      // 将传入日期转化成 Date 标准格式
      const now = new Date(date);
      // 将（UTC +0）时间转成当前选择时区的时间，不使用 this.offsetInMilliseconds 为简化方法调用
      const currentTime = new Date(now.getTime() + TimeZone.offsetInMilliseconds);
      // 格式化时间
      return moment(currentTime).format(formats[FORMAT_DATE.STANDARD]);
    }
    return '-';
  }

  /**
   * 将时间8.00转化成当前选择时区时间，可用于自定义格式渲染
   * @param date  时分秒的格式‘8:00:00’
   * @param utc0  true代表要转为utc-0格式，如果是false则是要utc-0转为当前时区
   */

  static convertToCurrentDuration(date: string, utc0 = false): string {
    // AntD ProTable 会将空值转成 element 对象，此处对其做隐式转换判断
    const nowDuration = moment().format('YYYY-MM-DD');
    const nowDate = `${nowDuration} ${date}`;

    if (date && date != null && date != '') {
      // 将传入日期转化成 Date 标准格式
      const now = new Date(nowDate);

      // 将（UTC +0）时间转成当前选择时区的时间，不使用 this.offsetInMilliseconds 为简化方法调用
      let currentTime = new Date(now.getTime() + TimeZone.offsetInMilliseconds);
      if (utc0) {
        // 将当前选择时区的时间转成UTC +0）时间
        currentTime = new Date(now.getTime() - TimeZone.offsetInMilliseconds);
      }
      // 格式化时间
      return moment(currentTime).format(formats[FORMAT_DATE.TIME]);
    }
    return '';
  }

  /**
   * 用于 columns transform 方法函数，转化日期控件时间为 UTC +0 时间
   * 使用示例 ProColumns:
   * {
   *  ...
   *  多个时间参数
   *    dataIndex: 'orderTime',
   *    search: {
   *      transform: TimeZone.transform(['orderTimeStart', 'orderTimeEnd']),
   *    }
   *  或单个时间参数
   *    dataIndex: 'preTimeEnd',
   *    valueType: 'date',
   *    search: {
   *      transform: TimeZone.transform(['preTimeEnd']),
   *    }
   *  ...
   * }
   * @param transformKeys 用于查询的key值，传入示例：['orderTimeStart', 'orderTimeEnd'], ['preTimeEnd']
   * @param formatIndex 单个日期选择项时，默认使用 formats[FORMAT_DATE.END] 格式，
   * @returns
   */
  static transform(
    transformKeys: [string, string?],
    formatIndex: FORMAT_DATE = FORMAT_DATE.END,
  ): (date: string[] | string) => Record<string, string> {
    const convertDate: Record<string, string> = {};
    return (dateInfo: string[] | string) => {
      // 日期区间选择器
      if (Array.isArray(dateInfo)) {
        dateInfo?.forEach((date: string, i: number) => {
          const formatDate = moment(date).format(formats[i]);
          convertDate[transformKeys[i]!] = this.convertToGreenwichTime(formatDate);
        });
        // 单个日期选择器
      } else {
        const formatDate = moment(dateInfo).format(formats[formatIndex]);
        convertDate[transformKeys[0]] = this.convertToGreenwichTime(formatDate);
      }
      return convertDate;
    };
  }

  /**
   * 同时提供 transform 与 render 方法，相较于上面的方法，灵活性较差，如无特殊的渲染格式可用此方法
   * 使用示例 ProColumns:
   * {
   *  dataIndex: 'orderTime',
   *  ...TimeZone.transformRender(['orderTimeStart', 'orderTimeEnd'], ['orderTime'])
   * }
   * @param transformKeys 用于查询的key值，传入示例：['orderTimeStart', 'orderTimeEnd']
   * @param renderKeys 用于渲染的key值，传入示例：['orderTime'], 需满足key在T中，T: Item类型声明
   * @param formatIndex 单个日期选择项时，默认使用 formats[FORMAT_DATE.END] 格式
   * @returns
   */
  static transformRender<T = Record<string, unknown>>(
    transformKeys: [string, string?],
    renderKeys: [keyof T],
    formatIndex: FORMAT_DATE = FORMAT_DATE.END,
  ): {
      search: {
        transform: (date: string | string[]) => Record<string, string>;
      };
      render: (_: unknown, record: T) => string;
    } {
    return {
      search: {
        transform: this.transform(transformKeys, formatIndex),
      },
      render: (_: unknown, record: T) => {
        const renderStr = renderKeys.reduce((result, key) => {
          const currentTime = this.convertToCurrentTime(record[key] as string);
          return `${result}-${currentTime}`;
        }, '');
        return renderStr?.substring(1);
      },
    };
  }
}
