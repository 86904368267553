import type { MenuProps } from 'antd';
import React from 'react';
import { Dropdown, Space } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { getLocale, s } from '@/locales';

import './index.scss';

const urlMap: Record<string, string> = {
  us: 'https://us.fh.express/',
  au: 'https://au.fh.express/',
  nz: 'https://nz.fh.express/',
  ca: 'https://ca.fh.express/',
};

const menuData: MenuProps['items'] = [
  {
    label: <span className="country-title">{s('国家或地区')}</span>,
    key: 'select',
    disabled: true,
  },
  {
    key: 'us',
    label: s('美国'),
  },
  {
    key: 'au',
    label: s('澳大利亚'),
  },
  {
    key: 'nz',
    label: s('新西兰'),
  },
  {
    key: 'ca',
    label: s('加拿大'),
  },
];

const CountryDropdown: React.FC = () => {
  let regionName = window.__multRegion;
  if (window.location.host.includes('us.')) {
    // us 挂载在 au 上，临时用于美区开放覆盖原有 region
    regionName = 'us';
  }
  const onClick: MenuProps['onClick'] = (info) => {
    if (info.key === regionName) {
      return;
    }
    const url = `${urlMap[info.key]}?locale=${getLocale()}`;
    window.open(url);
  };

  const { label: countryNmae } = menuData.find((item) => item?.key === regionName) || {
    label: s('国家或地区'),
  };

  return (
    <Dropdown
      overlayClassName="region-dropdown"
      menu={{ items: menuData, selectedKeys: [regionName], onClick }}
    >
      <Space className="region-label">
        <span>{countryNmae}</span>
        <DownOutlined className="region-icon" rev={undefined} />
      </Space>
    </Dropdown>
  );
};

export default CountryDropdown;
export { urlMap };
