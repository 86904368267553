/**
 * @type {Object} scoutConfig - Scout 配置信息
 * @property {string} [scoutConfig.projectName=common_sensors] - BI 定义的项目名，默认使用公共埋点项目的项目名
 * @property {string} [appKey.appKey] - 仓储项目名，默认使用 pacakage.name
 * @property {Map<string, string>} pageNames.pageNames - key 是 pageCode，value 是 pageAbsolutePath
 * @global {string} __multRegion - 全局地区标识
 */

const PROJECT_NAME: Record<string, string> = {
  eu: 'lme_spain',
  us: 'lme_usa',
  nz: 'lme_nz',
  ca: 'lme_ca',
};

const DATA_API: Record<string, string> = {
  eu: 'https://dataapi-eu.lmexpress.com',
  us: 'https://dataapi-us.lmexpress.com',
  nz: 'https://dataapi.lmexpress.com',
  ca: 'https://dataapi-ca.lmexpress.com',
};

const getProjectName = () => {
  const {
    location: { host },
    __multRegion,
  } = window;
  if (host === 'lme-h5.fe.test2.lmeapp.com' || host.includes(':')) {
    return 'lme-test';
  }
  if (host === 'qfresh.com.es') {
    return 'qdfresh_spain';
  }
  return PROJECT_NAME[__multRegion] || 'lme';
};

const getServerDomain = () => {
  const {
    location: { host },
    __multRegion,
  } = window;
  if (host === 'qfresh.com.es') {
    return 'https://dataapi-eu.qdfresh.net';
  }
  return DATA_API[__multRegion] || 'https://dataapi.lmexpress.com';
};

const scoutConfig = {
  projectName: getProjectName(),
  appKey: 'lme-h5',
  serverDomain: getServerDomain(), // 埋点服务域名
  pageNames: {
    '/': 'home',
    '/faq': 'question',
    '/pc/faq': 'question',
  },
  sdk_url: '//static.lmexpress.com/js/sensors-vtrack.min.v1.js',
};

export default scoutConfig;
