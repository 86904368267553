export default {
  '订单查询': '订单查询',
  '帮助？': '帮助？',
  '寄件地': '寄件地',
  '预计送达': '预计送达',
  '快递单号': '快递单号',
  '您的包裹': '您的包裹',
  '完整路径': '完整路径',
  '更多': '更多',
  '查询其他包裹': '查询其他包裹',
  '已送达': '已送达',
  '${realReceiverName} 已签收': '${realReceiverName} 已签收',
  '配送评价': '配送评价',
  '允许我们与您取得联系？': '允许我们与您取得联系？',
  '同意': '同意',
  '不同意': '不同意',
  '备注': '备注',
  '添加备注': '添加备注',
  '修改备注': '修改备注',
  '如果包裹当前正在配送中，司机可能看不到，暂不支持通过备注修改地址': '如果包裹当前正在配送中，司机可能看不到，暂不支持通过备注修改地址',
  '如果您有需要提醒司机的事宜,可以通过备注告诉他': '如果您有需要提醒司机的事宜,可以通过备注告诉他',
  '确定': '确定',
  '取消': '取消',
  '系统显示我的包裹“已丢失”，我应该怎么办？': '系统显示我的包裹“已丢失”，我应该怎么办？',
  '很抱歉，我们会尽快联系卖家进行赔偿。请联络卖家处理。': '很抱歉，我们会尽快联系卖家进行赔偿。请联络卖家处理。',
  '系统显示我的包裹“已签收”，但是我没找到，我应该怎么办？': '系统显示我的包裹“已签收”，但是我没找到，我应该怎么办？',
  '请参考签收图片，并询问邻居或大厦管理员。如果仍未找到，建议联络警方处理。': '请参考签收图片，并询问邻居或大厦管理员。如果仍未找到，建议联络警方处理。',
  '系统显示我的包裹“已签收”，但是签收图片不是我家，我应该怎么办？': '系统显示我的包裹“已签收”，但是签收图片不是我家，我应该怎么办？',
  '很抱歉，请将Tracking no、系统签收时间、正确地址信息（附正确地址照片）、包裹价值（附订单截图）电邮至我们的邮箱customer_service@fasthorse.com.au，以便我们进一步跟进。': '很抱歉，请将Tracking no、系统签收时间、正确地址信息（附正确地址照片）、包裹价值（附订单截图）电邮至我们的邮箱customer_service@fasthorse.com.au，以便我们进一步跟进。',
  '我买的东西少件了/这不是我买的东西，我应该怎么办？': '我买的东西少件了/这不是我买的东西，我应该怎么办？',
  '很抱歉，FHE专注尾程配送，我们只负责送达包裹。包裹内容物有问题请及时联系商家处理。': '很抱歉，FHE专注尾程配送，我们只负责送达包裹。包裹内容物有问题请及时联系商家处理。',
  '我的包裹破损了，内容物已经损坏，我应该怎么办？': '我的包裹破损了，内容物已经损坏，我应该怎么办？',
  '请参考签收图片。若签收时外包装破损，请将Tracking no、系统签收时间、包裹照片、包裹价值（附订单截图）、其他有用资料（如有）电邮至我们的邮箱customer_service@fasthorse.com.au，以便我们进一步跟进。': '请参考签收图片。若签收时外包装破损，请将Tracking no、系统签收时间、包裹照片、包裹价值（附订单截图）、其他有用资料（如有）电邮至我们的邮箱customer_service@fasthorse.com.au，以便我们进一步跟进。',
  '系统显示我的包裹“已为您创建订单”，我何时才能收到？': '系统显示我的包裹“已为您创建订单”，我何时才能收到？',
  '请耐心等待，包裹尚未到达仓库，入库后我们将尽快安排派送。': '请耐心等待，包裹尚未到达仓库，入库后我们将尽快安排派送。',
  '系统显示我的包裹“待配送”，我何时才能收到？': '系统显示我的包裹“待配送”，我何时才能收到？',
  '请耐心等待，我们将尽快安排派送。': '请耐心等待，我们将尽快安排派送。',
  '系统显示我的包裹“派送中”，我何时才能收到？': '系统显示我的包裹“派送中”，我何时才能收到？',
  '请耐心等待，通常情况下司机将于24小时内送达，实际送达时间视情况而定。': '请耐心等待，通常情况下司机将于24小时内送达，实际送达时间视情况而定。',
  '系统显示我的包裹“派送失败返仓中”，我何时才能收到？': '系统显示我的包裹“派送失败返仓中”，我何时才能收到？',
  '请耐心等待，我们会尽快安排第二次派送，或将包裹退回至卖家。请关注物流信息。': '请耐心等待，我们会尽快安排第二次派送，或将包裹退回至卖家。请关注物流信息。',
  '系统显示我的包裹“已退运”，我何时才能收到？': '系统显示我的包裹“已退运”，我何时才能收到？',
  '很抱歉，我们已将包裹退回卖家，请联络卖家处理。': '很抱歉，我们已将包裹退回卖家，请联络卖家处理。',
  '系统显示我的包裹“已转运”，我何时才能收到？': '系统显示我的包裹“已转运”，我何时才能收到？',
  '请耐心等待，我们已将包裹转至其他公司配送。您仍可在我们的网站追踪物流。': '请耐心等待，我们已将包裹转至其他公司配送。您仍可在我们的网站追踪物流。',
  '为什么会显示“导航/地址错误”？': '为什么会显示“导航/地址错误”？',
  '很抱歉，可能是由于系统导航无法正确定位地址，我们将尽快安排转至其他公司配送或退回卖家。': '很抱歉，可能是由于系统导航无法正确定位地址，我们将尽快安排转至其他公司配送或退回卖家。',
  '我是否能到仓库自提？': '我是否能到仓库自提？',
  '抱歉，现时不支持仓库自取。请耐心等待，我们将尽快将包裹送达。': '抱歉，现时不支持仓库自取。请耐心等待，我们将尽快将包裹送达。',
  '我是否可以选择送达时间？': '我是否可以选择送达时间？',
  '抱歉，现时不支持选择送达时间。请耐心等待，我们将尽快将包裹送达。': '抱歉，现时不支持选择送达时间。请耐心等待，我们将尽快将包裹送达。',
  '我是否可以修改收货地址？': '我是否可以修改收货地址？',
  '抱歉，现时不支持修改送货地址。如有相关需求请联系卖家处理。': '抱歉，现时不支持修改送货地址。如有相关需求请联系卖家处理。',
  '我是否可以要求司机送至指定位置？': '我是否可以要求司机送至指定位置？',
  '抱歉，现时不支持要求送至指定位置，我们的司机将会放置在安全地点。': '抱歉，现时不支持要求送至指定位置，我们的司机将会放置在安全地点。',
  '我收到了一个不属于自己的包裹，我应该怎么办？': '我收到了一个不属于自己的包裹，我应该怎么办？',
  '感谢您的告知，很抱歉给您带来不便。您的反馈对我们至关重要，请将Tracking no、您的联系方式、错送地址信息电邮至我们的邮箱customer_service@fasthorse.com.au，以便我们进一步跟进。': '感谢您的告知，很抱歉给您带来不便。您的反馈对我们至关重要，请将Tracking no、您的联系方式、错送地址信息电邮至我们的邮箱customer_service@fasthorse.com.au，以便我们进一步跟进。',
  '我想知道寄件方/我想查询订单详情？': '我想知道寄件方/我想查询订单详情？',
  '很抱歉，FHE专注尾程配送，我们只负责送达包裹。关于包裹详情请及时联系商家处理。': '很抱歉，FHE专注尾程配送，我们只负责送达包裹。关于包裹详情请及时联系商家处理。',
  '常见问题': '常见问题',
  '其他问题': '其他问题',
  '此答案是否解决您的问题': '此答案是否解决您的问题',
  '已解决': '已解决',
  '未解决': '未解决',
  '${orderNo}配送信息如下': '${orderNo}配送信息如下',
  '未查询到${orderNo}订单信息': '未查询到${orderNo}订单信息',
  '物流查询': '物流查询',
  '查询': '查询',
  '请输入运单号查询': '请输入运单号查询',
  '查看签收情况': '查看签收情况',
  '请输入寄件人或收件人手机号后4位完成验证': '请输入寄件人或收件人手机号后4位完成验证',
  '输入不正确，请重新输入': '输入不正确，请重新输入',
  '包裹放在': '包裹放在',
  '配送情况': '配送情况',
  '包裹交给': '包裹交给',
  '签收人': '签收人',
  '收件人或同住人': '收件人或同住人',
  '前门': '前门',
  '后门': '后门',
  '前台或门卫': '前台或门卫',
  '信箱': '信箱',
  '快递柜': '快递柜',
  '其他位置': '其他位置',
  '取件码': '取件码',
  '联系我们': '联系我们',
  '请您输入收件人的姓查看签收信息': '请您输入收件人的姓查看签收信息',
  '请输入': '请输入',
  '首页': '首页',
  '向着你的': '向着你的',
  '理想生活前进': '理想生活前进',
  '今天就开始驾驶和赚钱': '今天就开始驾驶和赚钱',
  '下载 FastHorse Express APP': '下载 FastHorse Express APP',
  '使用自己的汽车，在空闲时间投送小件包裹': '使用自己的汽车，在空闲时间投送小件包裹',
  '每小时收入可达$30澳元': '每小时收入可达$30',
  '上手简单': '上手简单',
  '简洁而完善的在线培训系统，15分钟掌握所有工作内容；': '简洁而完善的在线培训系统，15分钟掌握所有工作内容；',
  '工作轻松': '工作轻松',
  '免费智能路线规划系统，辅助您用最短路程完成包裹投递；': '免费智能路线规划系统，辅助您用最短路程完成包裹投递；',
  '时间灵活': '时间灵活',
  'Individual contractor合作模式，工作时间自主安排；': 'Individual contractor合作模式，工作时间自主安排；',
  '收入保障': '收入保障',
  '收入记录清晰透明，每周自动结算打款。': '收入记录清晰透明，每周自动结算打款。',
  '加入这份事业，您需要具备：': '加入这份事业，您需要具备：',
  '1. 年满20周岁及以上': '1. 年满20周岁及以上',
  '2. 居住在墨尔本（这是FHE已开通服务的城市）': '2. 居住在FHE已开通服务的城市',
  '3. 拥有一辆符合条件的车': '3. 拥有一辆符合条件的车',
  '汽车、SUV、小型货车或货车任一种都可以。': '汽车、SUV、小型货车或货车任一种都可以。',
  '不同车型关系到一次可装包裹的数量，根据我们的经验，一般四门car一次可装载4h的包裹，SUV大约6.5h，无后座的van可装8h的包裹。': '不同车型关系到一次可装包裹的数量，根据我们的经验，一般四门car一次可装载4h的包裹，SUV大约6.5h，无后座的van可装8h的包裹。',
  '4. 有完整且无限制的、剩余有效期超过3个月的澳大利亚驾照': '4. 有完整且无限制的、剩余有效期超过3个月的当地驾照',
  '5. 完善的车辆保险': '5. 完善的车辆保险',
  '所需的车险除了对第三方人身伤害的强制险外，您还须包括第三方财产损害的保险': '所需的车险除了对第三方人身伤害的强制险外，您还须包括第三方财产损害的保险',
  '6. 拥有澳大利亚企业编号 (ABN)。': '6. 拥有可工作的证明（SSN、ABN、NZBN等信息）。',
  '7. 通过背景调查确认。': '7. 通过背景调查确认。',
  '今天就': '今天就',
  '开始驾驶和赚钱': '开始驾驶和赚钱',
  '手机扫描二维码下载 FastHorse Express App': '手机扫描二维码下载 FastHorse Express App',
  '选择语言': '选择语言',
  '国家或地区': '国家或地区',
  '澳大利亚': '澳大利亚',
  '美国': '美国',
  '新西兰': '新西兰',
  '加拿大': '加拿大',
  '欢迎使用PDA系统': '欢迎使用PDA系统',
  '使用飞书扫描二维码登录': '使用飞书扫描二维码登录',
  '请输入账号': '请输入账号',
  '请输入密码': '请输入密码',
  '账号不能为空': '账号不能为空',
  '密码不能为空': '密码不能为空',
  '使用账号密码登录': '使用账号密码登录',
  '登录': '登录',
  '请输入运单号': '请输入运单号',
  '在线客服': '在线客服',
  '待揽收': '待揽收',
  '包裹异常': '包裹异常',
  '您可能遇到的问题': '您可能遇到的问题',
  '什么时候能送达': '什么时候能送达',
  '修改收件地址': '修改收件地址',
  '添加配送备注': '添加配送备注',
  '已添加备注': '已添加备注',
  '添加备注后，我们会提醒司机查看。但如果司机正在配送中可能无法及时查看到备注信息。': '添加备注后，我们会提醒司机查看。但如果司机正在配送中可能无法及时查看到备注信息。',
  '${finishTime}在${receivedType}签收': '${finishTime}在${receivedType}签收',
  '我们暂未收到包裹，一般会在收到包裹之后3天完成配送': '我们暂未收到包裹，一般会在收到包裹之后3天完成配送',
  '请输入您的问题': '请输入您的问题',
  '验证身份信息失败，请重新输入': '验证身份信息失败，请重新输入',
  '请输入收件人的姓氏': '请输入收件人的姓氏',
  '您的包裹异常，我们正在处理': '您的包裹异常，我们正在处理',
  '请输入正确的邮箱': '请输入正确的邮箱',
  '提交失败，请重试': '提交失败，请重试',
  '提交成功': '提交成功',
  '请输入您的电子邮件地址': '请输入您的电子邮件地址',
  '我们将与您沟通结果': '我们将与您沟通结果',
  '已添加的备注': '已添加的备注',
  '添加失败，请重试': '添加失败，请重试',
  '会话已超时，可返回上一页重新进入': '会话已超时，可返回上一页重新进入',
  '预计今天送达': '预计今天送达',
  '未收到包裹': '未收到包裹',
  '请输入备注': '请输入备注',
  '为进一步协助我们调查此问题，以及确保解决问题，我们善意地请求您向我们提供以下信息': '为进一步协助我们调查此问题，以及确保解决问题，我们善意地请求您向我们提供以下信息',
  '房屋或邮箱照片': '房屋或邮箱照片',
  '请上传房屋或邮箱照片': '请上传房屋或邮箱照片',
  '图片上传大小不要超过10MB,请重新上传！': '图片上传大小不要超过10MB,请重新上传！',
  '签收图片不是我家': '签收图片不是我家',
  '签收图片是我家但是找不到包裹': '签收图片是我家但是找不到包裹',
  '其他': '其他',
  '邮箱': '邮箱',
  '欢迎加入FastHorse': '欢迎加入FastHorse',
  '手机号/电子邮箱': '手机号/电子邮箱',
  '手机号码或电子邮箱': '手机号码或电子邮箱',
  '请输入你的账号': '请输入账号',
  '请输入登录密码': '请输入登录密码',
  '请输入你的密码': '请输入你的密码',
  '找回密码': '找回密码',
  '密码必须是8-16位英文字母、数字、符号组合': '密码必须是8-16位英文字母、数字、符号组合',
  '密码设置成功': '密码设置成功',
  '忘记密码': '忘记密码',
  '请输入您的手机号码': '请输入您的手机号码',
  '手机号码': '手机号码',
  '短信验证码已通过短信已发送至': '短信验证码已通过短信已发送至',
  '如果你没有收到验证码，请检查垃圾信息': '如果你没有收到验证码，请检查垃圾信息',
  '重新发送验证码': '重新发送验证码',
  '获取验证码': '获取验证码',
  '设置登录密码': '设置登录密码',
  '请输入新密码': '请输入新密码',
  '下一步': '下一步',
  '完成': '完成',
  '未结算数据': '未结算数据',
  '历史账单': '历史账单',
  '司机数据': '司机数据',
  '退出登录': '退出登录',
  '刷新': '刷新',
  '历史数据': '历史数据',
  '合计金额': '合计金额',
  '佣金': '佣金',
  '配送费': '配送费',
  '补贴': '补贴',
  '扣款': '扣款',
  '配送数据': '配送数据',
  '个包裹': '个包裹',
  '箱金额': '箱金额',
  '待接单': '待接单',
  '待取货': '待取货',
  '配送中': '配送中',
  '待返仓': '待返仓',
  '等待接单': '等待接单',
  '派单时间': '派单时间',
  '等待取货': '等待取货',
  '计划取货时间': '计划取货时间',
  '取货已迟到': '取货已迟到',
  '个包裹剩余': '个包裹剩余',
  '计划完成时间': '计划完成时间',
  '配送已完成': '配送已完成',
  '完成配送时间': '完成配送时间',
  '${num}个异常包裹未返仓': '${num}个异常包裹未返仓',
  '暂无数据': '暂无数据',
  '${num}个包裹': '${num}个包裹',
  '详情': '详情',
  '最少保留一个': '最少保留一个',
  '完成箱数': '完成箱数',
  '完成包裹数': '完成包裹数',
  '数据趋势': '数据趋势',
  '数据明细': '数据明细',
  '(${num}条)': '(${num}条)',
  '序号': '序号',
  '日期': '日期',
  '司机姓名': '司机姓名',
  '电子邮箱': '电子邮箱',
  '结算状态': '结算状态',
  '未结算': '未结算',
  '已结算': '已结算',
  '查看明细': '查看明细',
  '税': '税',
  '注册日期': '注册日期',
  '所有司机': '所有司机',
  '开始日期': '开始日期',
  '结束日期': '结束日期',
  '请选择结束日期': '请选择结束日期',
  '司机统计': '司机统计',
  '注册司机': '注册司机',
  '审核通过司机': '审核通过司机',
  '最近7天活跃司机': '最近7天活跃司机',
  '封禁司机': '封禁司机',
  '司机明细': '司机明细',
  '(${num}个)': '(${num}个)',
  '账号状态': '账号状态',
  '可配送周期': '可配送周期',
  '每日工作时长': '每日工作时长',
  '到底了~': '到底了~',
  '小时': '小时',
  '您的交货情况如何?': '您的交货情况如何?',
  '匿名': '匿名',
  '不满意': '不满意',
  '还行': '还行',
  '满意': '满意',
  '已完成评价': '已完成评价',
  '已超出可评价期限': '已超出可评价期限',
  '非常抱歉，我们很重视你的反馈， 你的反馈将对司机的收入造成影响，我们将会继续提升服务质量': '非常抱歉，我们很重视你的反馈， 你的反馈将对司机的收入造成影响，我们将会继续提升服务质量',
  '感谢你的反馈，我们将继续提升服务质量': '感谢你的反馈，我们将继续提升服务质量',
  '知道了': '知道了',
  '需在签收后 7 天内进行评价': '需在签收后 7 天内进行评价',
  '匿名评价': '匿名评价',
  '请详细描述你遇到的问题': '请详细描述你遇到的问题',
  '期望得到你的建议': '期望得到你的建议',
  '不满意，我要吐槽': '不满意，我要吐槽',
  '还行，这些方面还能做的更好': '还行，这些方面还能做的更好',
  '服务太棒了，我想夸夸配送员': '服务太棒了，我想夸夸配送员',
  '请选择或填写不满意的原因': '请选择或填写不满意的原因',
};
