import dayjs from 'dayjs';
import { urlMap } from '@/components/pc/countryDropdown';

export function noop(): void {
  // To nothing
}

/**
 * localStorage 封装
 */
export const LS = {
  set(key: string, data: any): void {
    localStorage.setItem(key, JSON.stringify(data));
  },
  get(key: string) {
    const item = localStorage.getItem(key);
    return item === null ? item : JSON.parse(item);
  },
  remove: localStorage.removeItem.bind(localStorage),
};

export function delay(second: number) {
  return new Promise((resolve) => {
    const timer = setTimeout(() => {
      clearTimeout(timer);
      resolve(true);
    }, second);
  });
}

export function getQuery(qs = ''): Record<string, string> {
  const queryStr = qs || window.location.search.slice(1);
  const query: Record<string, string> = {};
  queryStr.split('&').forEach((str: string) => {
    const [key, value] = str.split('=');
    if (!key && !value) {
      return;
    }
    query[key] = value;
  });
  return query;
}

export function replaceState(newQuery = {}): void {
  const oldSearch = window.location.search;
  const query = {
    ...getQuery(),
    ...(newQuery as Record<string, string>),
  };
  const newQueryStr = Object.keys(query)
    .filter((_) => _)
    .map((key) => `${key}=${query[key]}`)
    .join('&');
  const newUrl = oldSearch
    ? window.location.href.replace(oldSearch, `?${newQueryStr}`)
    : `${window.location.href}?${newQueryStr}`;
  window.history.replaceState({}, '', newUrl);
}

export function isPC(): boolean {
  const { pathname } = window.location;
  return pathname === '/' || pathname.startsWith('/pc/');
}

export function pcClass(className: string): string {
  return isPC() ? `${className} ${className}-pc` : className;
}

export function isIOS(): boolean {
  const u = window.navigator.userAgent;
  return u.includes('iPhone') || u.includes('Mac') || u.includes('iPad');
}

function tryRedirectCurCountry(country?: string): boolean {
  const dfCountry = country || LS.get('DEFAULT_COUNTRY');
  if (!dfCountry) {
    return false;
  }
  const newHost = urlMap[dfCountry];
  if (dfCountry === window.__multRegion) {
    return true;
  }
  if (!newHost) {
    return false;
  }
  const target = window.location.href.replace(window.location.origin, newHost.slice(0, -1));
  window.location.href = target;
  return true;
}

export function redirectToCurCounrty(): void {
  if (window.location.host !== 'fh.express' || window.location.pathname !== '/') {
    return;
  }
  const success = tryRedirectCurCountry();
  if (success) {
    return;
  }
  fetch('https://speed.cloudflare.com/meta')
    .then((response) => response.json())
    .then((data) => {
      const { country } = data || {};
      const countryMap = {
        // US: 'us',
        // USA: 'us',
        CA: 'ca',
        CAN: 'ca',
        AU: 'au',
        AUS: 'au',
        NZ: 'nz',
        NZL: 'nz',
      };
      const curCountry = countryMap[country as 'AU'] || 'au';
      LS.set('DEFAULT_COUNTRY', curCountry);
      tryRedirectCurCountry(curCountry);
    });
}

// 生成唯一设备 ID
export function generateDeviceId() {
  let deviceId = localStorage.getItem('deviceId');
  if (!deviceId) {
    deviceId = Date.now().toString(36) + Math.random().toString(36)
      .substring(2, 7);
    localStorage.setItem('deviceId', deviceId);
  }
  return deviceId;
}

/**
 * 日期转毫秒
 * @param day
 * @returns
 */
export function dayToMs(day: number) {
  return day * 24 * 60 * 60 * 1000;
}

// 国家货币符号
const CURRENCY_SYMBOLS: Record<string, string> = {
  us: 'USD', // 美国的货币符号缩写是USD（美元）。
  au: 'AUD', // 澳大利亚的货币符号缩写是AUD（澳元）。
  nz: 'CAD', // 加拿大的货币符号缩写是CAD（加元）。
  ca: 'NZD', // 新西兰的货币符号缩写是NZD（新西兰元）。
};

/**
 * 获取国家货币符号
 * @returns Currency Symbol
 */
export function regionCurrencySymbol(): string {
  return CURRENCY_SYMBOLS[window.__multRegion] || '';
}

// 其他国家后期上线，日期格式初始默认为DD/MM/YYYY - DD/MM/YYYY
const DATE_FORMATS: Record<string, string> = {
  us: 'MM/DD/YYYY', // 美国日期格式：MM/DD/YYYY - MM/DD/YYYY
  au: 'DD/MM/YYYY', // 澳大利亚、新西兰日期格式：DD/MM/YYYY - DD/MM/YYYY
  nz: 'DD/MM/YYYY', // 澳大利亚、新西兰日期格式：DD/MM/YYYY - DD/MM/YYYY
  ca: 'YYYY/MM/DD', // 加拿大日期格式：YYYY/MM/DD - YYYY/MM/DD/YYYY
};
/**
 * 根据不同国家展示不同提起格式
 * @param date
 * @returns
 */
export function formatRegionDate(date?: Date | string | number): string {
  return date ? dayjs(date).format(DATE_FORMATS[window.__multRegion || 'DD/MM/YYYY']) : '-';
}

// formats 下标对照枚举
export enum FORMAT_DATE {
  START, // 起始日期格式下标
  END, // 结束日期格式下标
  STANDARD, // 标准日期格式下标
  SORT_DATE, // 年月日
  TIME, // 标准时间格式下标
}

// 自定义转换格式
export const formats = [
  'YYYY-MM-DD 00:00:00',
  'YYYY-MM-DD 23:59:59',
  'YYYY-MM-DD HH:mm:ss',
  'YYYY-MM-DD',
  'HH:mm:ss',
];

/**
 * 格式化日期、时间
 * @param transformKeys 转化后的变量名称
 * @param formatIndex 针对单个转化格式下标
 * @returns
 */
export function transformDates(
  transformKeys: [string, string?],
  formatIndex: FORMAT_DATE = FORMAT_DATE.SORT_DATE,
): (date: Date[] | Date) => Record<string, string> {
  const convertDate: Record<string, string> = {};
  return (dateInfo?: Date[] | Date) => {
    if (dateInfo) {
      if (Array.isArray(dateInfo)) {
        dateInfo?.forEach((date: Date, i: number) => {
          const formatDate = dayjs(date).format(formats[formatIndex]);
          convertDate[transformKeys[i]!] = formatDate;
        });
      } else {
        const formatDate = dayjs(dateInfo).format(formats[formatIndex]);
        convertDate[transformKeys[0]] = formatDate;
      }
    }
    return convertDate;
  };
}

/**
 * 格式化金钱
 * @param num 金额
 */
export const formatMoney = (num: number): string => `${num}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

/**
 * 格式化包含小数点时保留多位小数，默认2位
 * @param number
 * @returns
 */
export const truncateDecimal = (number: number | string, decimals = 2): string => {
  const numberStr = `${number}`;
  if (/\./.test(numberStr)) {
    const decimalIndex = numberStr.indexOf('.');
    const decimalPart = numberStr.substring(decimalIndex + 1);
    const truncatedDecimalPart = decimalPart.padEnd(decimals, '0').substring(0, decimals);
    return numberStr.substring(0, decimalIndex + 1) + truncatedDecimalPart;
  }
  return numberStr || '0';
};

/** 国家区号 */
const areaCode = {
  au: '61',
  us: '1',
  ca: '1',
  nz: '64',
};

/** 获取当前国家区号 */
export const getCurrentAreaCode = (): string => areaCode[window.__multRegion] || '';
